/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, ReactNode } from 'react';
import { useRefresToken } from '@src/queries/aut';
import { useNavigate } from 'react-router-dom';
import { Path } from '@src/navigations/routes';
// import { useLogout } from '@src/queries/aut';
import { isUndefined } from '@src/utils/type';
type AuthProviderProps = {
  children: ReactNode;
};

type AuthContextType = {
  userName: string;
  Userpassword: string;
  isLoggedIn: string | any;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider = ({ children }: AuthProviderProps) => {
  const navigate = useNavigate();
  const token = localStorage.getItem('authToken');
  const DURATION_4_MIN = 4 * 60 * 1000;
  useRefresToken({
    enabled: !!token,
    refetchIntervalInBackground: true,
    refetchInterval: DURATION_4_MIN,
    staleTime: DURATION_4_MIN,
    retry: false,
    onSuccess: (data) => {
      localStorage.setItem('authToken', data);
    },
    onError: () => {
      localStorage.removeItem('authToken');
      navigate(Path.Login);
    }
  });

  const values: AuthContextType = {
    userName: '',
    Userpassword: '',
    isLoggedIn: !!token
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAccount = () => {
  const context = useContext(AuthContext);
  if (isUndefined(context)) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};
