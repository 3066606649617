import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import StyleProvider from '@sendsprint/ui-react/dist/providers/StyleProvider/StyleProvider';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import reportWebVitals from './reportWebVitals';
import QueryProvider from '@src/providers/query-provider';
import { ToastProvider } from '@src/contexts';
import AuthProvider from './contexts/aut-context';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <HelmetProvider>
        <StyleProvider>
          <ToastProvider autoDismiss={true} placement="bottom-right">
            <QueryProvider>
              <AuthProvider>
                <App />
              </AuthProvider>
            </QueryProvider>
          </ToastProvider>
        </StyleProvider>
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// "build": "yarn build-files", this is changed in package.json file
