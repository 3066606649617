/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { UserAxiosConfig, addTokenHeader, RequestConfig } from '@src/config/AxiosConfig';
import ClientApi from '@src/types/client';
import { useEffect } from 'react';
// import { clearState, getState, setState } from '@src/utils/stateStorage';

import { AxiosResponse } from 'axios';

export const loginService = async (
  payload: ClientApi.Account.Login.Request
): Promise<ClientApi.General.LoggedInUser> => {
  const config = addTokenHeader('');

  return UserAxiosConfig.post<
    ClientApi.Account.Login.Response,
    AxiosResponse<ClientApi.Account.Login.Response>,
    ClientApi.Account.Login.Request
  >(`/api/v1/admin/login`, payload, config).then((data: any) => {
    return data.data;
  });
};

export const ForgetPasswordService = async (
  payload: ClientApi.Account.ForgotPassword.Request
): Promise<ClientApi.General.ForgotPasswordInUser> => {
  const config = addTokenHeader('');

  return UserAxiosConfig.post<
    ClientApi.Account.ForgotPassword.Response,
    AxiosResponse<ClientApi.Account.ForgotPassword.Response>,
    ClientApi.Account.ForgotPassword.Request
  >(
    `/api/v1/admin/reset-password-by-email/initiate?AdminEmail=${payload.email}`,
    payload,
    config
  ).then((data: any) => {
    return data;
  });
};

export const ResetPasswordService = async (
  payload: ClientApi.Account.ResetPassword.Request
): Promise<ClientApi.General.ResetPasswordUser> => {
  const config = addTokenHeader('');

  return UserAxiosConfig.put<
    ClientApi.Account.ResetPassword.Response,
    AxiosResponse<ClientApi.Account.ResetPassword.Response>,
    ClientApi.Account.ResetPassword.Request
  >(`/api/v1/admin/reset-password-by-email/complete`, payload, config).then((data: any) => {
    return data;
  });
};

export const refreshTokenService = async () => {
  const token = localStorage.getItem('authToken');

  const configWithToken = addTokenHeader(token || '');
  return UserAxiosConfig.put(`/api/v1/admin/refresh-session-token`, {}, configWithToken).then(
    (res) => {
      return res.data.Data.SessionToken;
    }
  );
};

export const logoutService = async () => {
  const token = localStorage.getItem('authToken');

  const configWithToken = addTokenHeader(token || '');
  return UserAxiosConfig.put(`/api/v1/admin/logout`, {}, configWithToken).then((res) => {
    return res.data;
  });
};
