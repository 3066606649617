export type EnvData = {
  API_BASE_URL: string;
  API_STAGING_BASE_URL: string;
  API_APPLICATION_ID: string;
  API_SECRET_KEY: string;
};

export function getEnvData(): Readonly<EnvData> {
  //   const ENVIRONMENT = `${process.env.REACT_APP_ENV}`;
  //   const IS_DEV = ENVIRONMENT === 'development';
  // console.log(process.env.REACT_APP_API_SECRET_KEY, 'key');
  // console.log(process.env.REACT_APP_API_APPLICATION_ID, 'id');
  // console.log(process.env.REACT_APP_API_STAGING_BASE_URL, 'urlstage');

  return {
    API_BASE_URL: `${process.env.REACT_APP_API_BASE_URL}`,
    API_STAGING_BASE_URL: `${process.env.REACT_APP_API_STAGING_BASE_URL}`,
    API_SECRET_KEY: `${process.env.REACT_APP_API_SECRET_KEY}`,

    API_APPLICATION_ID: `${process.env.REACT_APP_API_APPLICATION_ID}`
  };
}

// under context auth-context.ts  for refresh token api usage in b2b
