import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface Props {
  isAuthenticated: boolean;
  loggedInRedirectPath: string;
}

const PublicRoute: React.FC<Props> = ({ isAuthenticated, loggedInRedirectPath }) => {
  if (isAuthenticated) {
    /**
     * When logged in we want the public pages like login etc, to be directed to the dashboard
     */
    return <Navigate to={loggedInRedirectPath} />;
  }

  return <Outlet />;
};

export default PublicRoute;
