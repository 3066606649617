import Loader from '@src/components/loader';
import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import { Path } from './routes';
import { useAccount } from '@src/contexts/aut-context';

export const LoginPage = lazy(
  () => import(/* webpackChunkName: 'loginPage' */ '@src/pages/auth/login')
);
export const ResetpasswordPage = lazy(() => import('@src/pages/auth/resetPassword'));
export const ForgetpasswordPage = lazy(() => import('@src/pages/auth/forgetPassword'));
export const CheckMailPage = lazy(() => import('@src/pages/auth/checkMail'));

const AdminRoutes = lazy(
  () => import(/* webpackChunkName: 'AdminRoutes' */ './subRoutes/AdminRoutes')
);

const AdminVerifyEmail = lazy(
  () =>
    import(/* webpackChunkName: 'notFoundPage' */ '@src/pages/admin/adminUsers/adminVerifyEmail')
);
const NotFound = lazy(() => import(/* webpackChunkName: 'notFoundPage' */ '@src/pages/notFound'));

const RouterConfig = () => {
  // this is to be replaced with whatever you use to authenticate the user
  /**
   * cheking if the token is available
   */
  const { isLoggedIn } = useAccount();

  return (
    <Suspense fallback={<Loader showTimedOutScreen />}>
      <Routes>
        <Route
          path={Path.Home}
          element={
            isLoggedIn ? (
              <Navigate to={Path.Dashboard} replace />
            ) : (
              <Navigate to={Path.Login} replace />
            )
          }
        />
        {/* Public routes */}
        <Route
          element={
            <PublicRoute loggedInRedirectPath={Path.Dashboard} isAuthenticated={isLoggedIn} />
          }>
          <Route path={Path.Login} element={<LoginPage />} />
          <Route path={Path.ResetPassword} element={<ResetpasswordPage />} />
          <Route path={Path.ForgetPassword} element={<ForgetpasswordPage />} />
          <Route path={Path.checkMail} element={<CheckMailPage />} />
          <Route path={Path.AdminVerifyEmail} element={<AdminVerifyEmail />} />
        </Route>
        {/* Protected receiver routes */}
        <Route element={<ProtectedRoute isAuthenticated={isLoggedIn} />}>
          <Route path={'/admin/*'} element={<AdminRoutes />} />
        </Route>
        {/* admin verified email route */}
        {/* Not found */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default RouterConfig;
